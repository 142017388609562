<template>
<vs-row justify="flex-start" class="admin" style="padding-left: 270px; padding-top: 50px;" v-if="$parent.user != null" :style="{'height': active == 'builder' ? 'unset' : '75vh'}" :class="{ 'admin-mobile': isMobile }">
    <input type="file" ref="fileInput" style="display: none;" @change="fileInputChange" accept="image/png" />
    <vs-sidebar absolute v-model="active" open style="height: 81vh; " :reduce="isMobile">

        <vs-sidebar-item id="products">
            <template #icon>
                <i class='bx bxs-purchase-tag-alt'></i>
            </template>
            Prodotti
        </vs-sidebar-item>
        <vs-sidebar-item id="users" disabled>
            <template #icon>
                <i class='bx bxs-group'></i>
            </template>
            Utenti ({{ users_count }})
        </vs-sidebar-item>
        <vs-sidebar-item id="orders">
            <template #icon>
                <i class='bx bxs-cart'></i>
            </template>
            Ordini
        </vs-sidebar-item>
        <vs-sidebar-item id="coupons">
            <template #icon>
                <i class='bx bxs-badge-dollar'></i>
            </template>
            Coupon
        </vs-sidebar-item>
        <!-- presets-->
        <vs-sidebar-item id="presets">
            <template #icon>
                <i class='bx bx-palette'></i>
            </template>
            Predefiniti
        </vs-sidebar-item>
        <!--<vs-sidebar-item id="invoices">
            <template #icon>
                <i class='bx bxs-credit-card'></i>
            </template>
            Fatture
        </vs-sidebar-item>-->


    

        <!-- builder-->
        <vs-sidebar-item id="builder" disabled>
            <template #icon>
                <i class='bx bx-layout'></i>
            </template>
            Builder
        </vs-sidebar-item>

    </vs-sidebar>

   
    <vs-dialog prevent-close v-model="invoiceDialog" class="dialog-large" :loading="invoiceDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                <b>Convalida pagamento.</b>
            </h4>

        </template>

        <vs-row v-if="invoice != null">
            <!-- BASE FIELDS -->

            <vs-row style="margin-top: 20px;">
                <vs-input type="number" label="Importo" v-model="invoice.amount" block />
            </vs-row>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveInvoice">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog prevent-close v-model="editProductDialog" class="dialog-large " :loading="editProductDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Modifica <b>{{ product != null ? product.title : '' }}</b>
            </h4>

        </template>

        <vs-row v-if="product != null">
            <!-- BASE FIELDS -->
            <!-- show product preview here -->
            <vs-row style="margin-top: 20px;" v-if="product&& product.previewFileUrl != null" justify="center">
                    <img v-if="product.fileId != null" :src="product.previewFileUrl" style="max-width: 100%; max-height: 200px;" />
            </vs-row>

            <vs-row justify="space-between">
                <vs-col w="12">
                    <vs-button icon color="primary" block @click="uploadFile(product.fileId, 'productPreview', 'image/*')">
                        <i class='bx bx-upload'></i>
                        {{ product.fileId != null ? 'Sostituisci anteprima prodotto' : 'Carica anteprima prodotto' }}
                    </vs-button>
                </vs-col>

                
            </vs-row>

            <vs-row style="gap: 30px;">
                <vs-input type="text" label="Titolo" v-model="product.title" block />
                <vs-input type="text" label="Descrizione" v-model="product.description" block />

                <vs-input type="number" label="Larghezza" v-model="product.width" block />
                <vs-input type="number" label="Altezza" v-model="product.height" block />
                <!-- margintop, marginleft, marginright, marginbottom -->
                 <vs-row style="gap: 10px;" justify="space-between">
                <vs-input type="number" label="Margine Superiore" v-model="product.marginTop"  style="width: 22%;"/>
                <vs-input type="number" label="Margine Sinistro" v-model="product.marginLeft"  style="width: 22%;"/>
                <vs-input type="number" label="Margine Destro" v-model="product.marginRight" style="width: 22%;"/>
                <vs-input type="number" label="Margine Inferiore" v-model="product.marginBottom"  style="width: 22%;"/>
                    </vs-row>
            </vs-row>



            <!-- VARIANT MANAGEMENT -->
            <vs-row justify="space-between" align="center">
                <h2>Varianti</h2>
                <vs-button icon color="primary" @click="addProductVariant">
                    <i class='bx bx-plus'></i>
                    Aggiungi nuova
                </vs-button>
            </vs-row>
            <vs-table striped style="width: 100%;">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Id
                        </vs-th>
                        <vs-th>
                            Pagine
                        </vs-th>
                        <vs-th>
                            Dorsino
                        </vs-th>
                        <vs-th>
                            Prezzo
                        </vs-th>
                        <vs-th>
                            Prezzo Digitale
                        </vs-th>
                        <vs-th>
                            Stipe PID
                        </vs-th>
                        <vs-th>
                            Stipe PID Digitale
                        </vs-th>

                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in product.productVariants" :is-selected="tr == productVariant" @click="productVariant = tr">
                        <vs-td>
                            {{ tr.id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.pages }}
                        </vs-td>
                        <vs-td>
                            {{ tr.dorsino }}mm <vs-button icon @click="openInBuilder(tr.spineLayout, tr)">
                                <i class="bx bx-layout"></i>
                            </vs-button>
                        </vs-td>
                        <vs-td>
                            {{ tr.price }}
                        </vs-td>
                        <vs-td>
                            {{ tr.digitalPrice }}
                        </vs-td>
                        <vs-td>
                            {{ tr.stripePriceId }}
                        </vs-td>
                        <vs-td>
                            {{ tr.digitalStripePriceId }}
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>

            
            <!-- LAYOUT MANAGEMENT -->
            <vs-row justify="space-between" align="center">
                <h2>Layout</h2>
                <vs-button icon color="primary" @click="addLayout">
                    <i class='bx bx-plus'></i>
                    Aggiungi nuovo
                </vs-button>
            </vs-row>

            <vs-row>
                <vs-col w="3" v-for="tr in product.layouts" @click="layout = layout" :key="tr.id" style="padding: 8px;">

                    <vs-card>
                        <template #title>
                        <h3>{{ tr.name }}</h3>
                        </template>
                        <template #img>
                         <img :src="tr.previewFileUrl" v-if="tr.previewFileUrl"  />
                         <img v-else :src="placeholder" />
                        </template>
                        <template #text>
                            <!-- Type field can be 'StartCoverFront', 'StartCoverBack', 'EndCoverFront', 'EndCoverBack', 'Page'-->
                            <p v-if="tr.type == 'StartCoverFront'">Copertina iniziale (Esterna)</p>
                            <p v-else-if="tr.type == 'StartCoverBack'">Copertina iniziale (Interna)</p>
                            <p v-else-if="tr.type == 'EndCoverFront'">Copertina finale (Esterna)</p>
                            <p v-else-if="tr.type == 'EndCoverBack'">Copertina finale (Interna)</p>
                            <p v-else-if="tr.type == 'Page'">Pagina</p>
                        </template>
                        <template #interactions>
                        <vs-button icon @click="layout = tr">
                            <i class="bx bx-edit"></i>
                        </vs-button>
                        <!-- editor-->
                        <vs-button icon @click="openInBuilder(tr)">
                            <i class="bx bx-layout"></i>
                        </vs-button>
                        </template>
                    </vs-card>
                </vs-col>
            </vs-row>
            

        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveProduct">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>
    <vs-dialog prevent-close v-model="editLayoutDialog" class="dialog-large" :loading="editLayoutDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Modifica <b>Layout #{{ layout != null ? layout.id : '' }}</b>
            </h4>

        </template>

        <vs-row v-if="layout != null" >
            
            <vs-row style="gap: 30px;">
                <vs-input type="text" label="Nome" v-model="layout.name" block />
               <vs-select v-model="layout.type" label="Tipo" block>
                    <vs-option label="Copertina iniziale (Esterna)" value="StartCoverFront">Copertina iniziale (Esterna)</vs-option>
                    <vs-option label="Copertina iniziale (Interna)" value="StartCoverBack">Copertina iniziale (Interna)</vs-option>
                    <vs-option label="Copertina finale (Esterna)" value="EndCoverFront">Copertina finale (Esterna)</vs-option>
                    <vs-option label="Copertina finale (Interna)" value="EndCoverBack">Copertina finale (Interna)</vs-option>
                    <vs-option label="Pagina" value="Page">Pagina</vs-option>
                </vs-select>

            </vs-row>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="editLayout">
                    Salva modifiche
                </vs-button>
                <vs-button block border danger @click="deleteLayout">
                    Elimina
                </vs-button>
            </div>
        </template>
    </vs-dialog>


    <vs-dialog prevent-close v-model="productVariantDialog" class="dialog-large" :loading="productVariantDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                <b>Variante prodotto.</b>
            </h4>

        </template>

        <vs-row v-if="productVariant != null" style="gap: 30px;">
            <vs-row>
                <vs-input type="number" label="Pagine" v-model="productVariant.pages" block />
            </vs-row>
            <vs-row>
                <!-- dorsino (mm)-->
                <vs-input type="number" label="Dorsino (mm)" v-model="productVariant.dorsino" block />
            </vs-row>
            <vs-row>
                <vs-input type="number" label="Prezzo" v-model="productVariant.price" block />
            </vs-row>
            <vs-row>
                <vs-input type="number" label="Prezzo Digitale" v-model="productVariant.digitalPrice" block />
            </vs-row>
            <vs-row>
                <vs-input type="text" label="Stripe Price ID" v-model="productVariant.stripePriceId" block />
            </vs-row>
            <vs-row>
                <vs-input type="text" label="Digital Stripe Price ID" v-model="productVariant.digitalStripePriceId" block />
            </vs-row>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveProductVariant">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    

    <!-- BEGIN PRODUCT MANAGEMENT -->
    <template v-if="active == 'products'">

        <vs-table striped style="width: 100%;" v-model="product">
            <template #thead>
                <vs-tr>
                    <vs-th style="width: 5%;">
                        Id
                    </vs-th>
                    <vs-th style="width: 30%;">
                        Titolo
                    </vs-th>
                    <vs-th>
                        Larghezza
                    </vs-th>
                    <vs-th>
                        Altezza
                    </vs-th>
                    <vs-th>
                        Margini
                    </vs-th>
                    <vs-th>
                        Layouts
                    </vs-th>
                    <vs-th>
                        Temi
                    </vs-th>
                    <vs-th>
                        Ordini
                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in products" @click="showProduct(tr)" :is-selected="tr == product">
                    <vs-td>
                        {{ tr.id }}
                    </vs-td>
                    <vs-td>
                        {{ tr.title }}
                    </vs-td>

                    <vs-td>
                        {{ tr.width }}mm
                    </vs-td>
                    <vs-td>
                        {{ tr.height }}mm
                    </vs-td>
                    <vs-td>
                        {{ tr.marginTop }}mm, {{ tr.marginLeft }}mm, {{ tr.marginRight }}mm, {{ tr.marginBottom }}mm
                    </vs-td>

                    <vs-td>
                        {{ tr.layouts.length }}
                    </vs-td>
                    <vs-td>
                        0
                    </vs-td>
                    <vs-td>
                        {{ tr.orders.length }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </template>
    <!-- END PRODUCT MANAGEMENT -->

    <!-- BEGIN USER MANAGEMENT-->
    <template v-if="active == 'users'">
        <vs-dialog prevent-close v-model="editUserDialog" class="dialog-large" :loading="editUserDialogLoading">
            <template #header>
                <h4 style="margin-bottom: 0;">
                    Modifica <b>{{ user != null ? user.email : '' }}</b>
                </h4>

            </template>

            <vs-row v-if="user != null">
                <!-- BASE FIELDS -->
                <vs-row style="gap: 30px;">
                    <vs-input type="text" label="Email" disabled v-model="user.email" block />
                    <vs-input type="text" label="Nome" v-model="user.name" block />
                    <vs-input type="text" label="Cognome" v-model="user.surname" block />
                    <vs-input type="text" label="Indirizzo" v-model="user.address" block />
                    <vs-input type="text" label="Indirizzo No." v-model="user.addressNo" block />
                    <vs-input type="text" label="Città" v-model="user.city" block />
                    <vs-input type="text" label="CAP" v-model="user.postalCode" block />
                    <vs-input type="text" label="Paese" v-model="user.country" block />
                    <vs-input type="text" label="Cellulare" v-model="user.mobile" block />
                    <vs-input type="text" label="VAT" v-model="user.vat" block />
                    <vs-input type="text" label="Codice fiscale" v-model="user.cf" block />
                    <vs-input type="text" label="Stripe CID" v-model="user.stripeCustomerId" block />
                    <vs-checkbox v-model="user.isAdmin">
                        Amministratore
                    </vs-checkbox>
                </vs-row>

                <!-- THEME MANAGEMENT -->
                <vs-row justify="space-between" align="center">
                    <h2>Ordini</h2>

                </vs-row>
                <vs-table striped style="width: 100%;">
                    <template #thead>
                        <vs-tr>
                            <vs-th style="width: 5%;">
                                Id
                            </vs-th>

                        </vs-tr>
                    </template>
                    <template #tbody>
                        <vs-tr :key="i" v-for="(tr, i) in user.orders">
                            <vs-td>
                                {{ tr.id }}
                            </vs-td>

                        </vs-tr>
                    </template>
                </vs-table>

            </vs-row>

            <template #footer>
                <div class="footer-dialog">
                    <vs-button block @click="saveUser">
                        Salva modifiche
                    </vs-button>
                </div>
            </template>
        </vs-dialog>
        <vs-table striped style="width: 100%;" v-model="user">
            <template #thead>
                <vs-tr>
                    <vs-th style="width: 5%;">
                        Id
                    </vs-th>
                    <vs-th style="width: 30%;">
                        Email
                    </vs-th>
                    <vs-th>
                        Nome
                    </vs-th>
                    <vs-th>
                        Cognome
                    </vs-th>
                    <vs-th>
                        Indirizzo
                    </vs-th>
                    <vs-th>
                        Città
                    </vs-th>
                    <vs-th>
                        CAP
                    </vs-th>
                    <vs-th>
                        Amministratore
                    </vs-th>
                    <vs-th>
                        Ordini
                    </vs-th>
                    <vs-th>
                        Post
                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in users" @click="user = tr" :is-selected="tr == user">
                    <vs-td>
                        {{ tr.id }}
                    </vs-td>
                    <vs-td>
                        {{ tr.email }}
                    </vs-td>
                    <vs-td>
                        {{ tr.name }}
                    </vs-td>
                    <vs-td>
                        {{ tr.surname }}
                    </vs-td>
                    <vs-td>
                        {{ tr.address }} {{ tr.addressNo }}
                    </vs-td>
                    <vs-td>
                        {{ tr.city }}
                    </vs-td>
                    <vs-td>
                        {{ tr.postalCode }}
                    </vs-td>
                    <vs-td>
                        {{ tr.isAdmin ? 'Sì' : 'No' }}
                    </vs-td>
                    <vs-td>
                        {{ tr.orders.length }}
                    </vs-td>

                    <vs-td>
                        {{ tr.posts.length }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </template>

    <!-- BEGIN USER MANAGEMENT-->
    <template v-if="active == 'orders'">
        <vs-input type="text" v-model="orderSearch" label="Cerca" />
        <vs-table striped style="width: 100%;" v-model="user">
            <template #thead>
                <vs-tr>
                    <vs-th style="width: 5%;">
                        Id
                    </vs-th>
                    <vs-th style="width: 30%;">
                        Prodotto
                    </vs-th>
                    <vs-th>
                        Pagine
                    </vs-th>
                    <vs-th>
                        Versione
                    </vs-th>
                    <vs-th>
                        Qty
                    </vs-th>
                    <vs-th>
                        Stato Pagamento
                    </vs-th>
                    <vs-th>
                        Data
                    </vs-th>
                    <vs-th>
                        Stato Elaborazione
                    </vs-th>
                    <vs-th>
                        Importo
                    </vs-th>

                    <vs-th>
                        Origine
                    </vs-th>
                    <vs-th>
                        Coupon
                    </vs-th>
                    <vs-th>

                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in orders">
                    <vs-td>
                        {{ tr.id }}
                    </vs-td>
                    <vs-td>
                        {{ tr.product.title }}
                    </vs-td>
                    <vs-td>
                        {{ tr.pages}}
                    </vs-td>
                    <vs-td>
                        {{ tr.digitalVersionOnly ? 'Digitale' : 'Completa' }}
                    </vs-td>
                    <vs-td>
                        {{ tr.qty }}
                    </vs-td>
                    <vs-td>
                        {{ tr.invoices[0].status }}
                    </vs-td>
                    <vs-td>
                        {{ (new Date(tr.invoices[0].createdAt).toLocaleDateString()) }} {{ (new Date(tr.invoices[0].createdAt).toLocaleTimeString()) }}
                    </vs-td>
                    <vs-th>
                        <template v-if="tr.isFailed">
                            Fallito
                        </template>
                        <template v-else-if="tr.isReady">
                            Completato
                        </template>
                        <template v-else-if="tr.inProgress">
                            In lavorazione
                        </template>
                        <template v-else>
                            In attesa
                        </template>
                    </vs-th>
                    <vs-td>
                        € {{ tr.invoices[0].amount.toFixed(2) }}
                    </vs-td>
                    <vs-td>
                        {{ tr.origin || '-' }}
                    </vs-td>
                    <vs-td>
                        {{ tr.coupon || '-' }}
                    </vs-td>
                    <vs-td style="width: 40%;">
                        <vs-row direction="row" justify="flex-end">
                            <vs-button v-if="tr.invoices[0].stripeInvoiceId == '' || tr.invoices[0].stripeInvoiceId == null && tr.invoices[0].status != 'PAID'" @click="invoice = tr.invoices[0]" dark>Convalida</vs-button>
                            <vs-button :disabled="!tr.isReady" @click="resendEmail(tr)" :loading="resendingEmail">Re-Invia Email</vs-button>
                            <vs-button @click="viewCustomer(tr)">Vis. Cliente</vs-button>
                            <vs-button @click="viewInvoice(tr)">Vis. Ricevuta</vs-button>
                            <vs-button @click="downloadOrder(tr)">Download</vs-button>
                        </vs-row>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </template>

   
    <template v-if="active == 'coupons'">
        <Coupons />
    </template>
    <template v-if="active == 'builder'">
        <Builder :builder-data="builderData" />
    </template>

    <template v-if="active == 'presets'">
        <BuilderImages />
    </template>
</vs-row>
</template>

<script>
import placeholder from '@/assets/placeholder.png';

import {
    apiCall,
    baseUrl
} from '../client';

import {
    s3Client
} from '../s3Client';

import Coupons from './Admin/Coupons.vue';
import Builder from './Admin/Builder.vue';
import BuilderImages from './Admin/BuilderImages.vue';
export default ({
    components: {
        Coupons,
        Builder,
        BuilderImages
    },
    setup() {

    },

    data() {
        return {
            active: '',

            products: [],
            product: null,
            editProductDialog: false,
            editProductDialogLoading: false,

            layout: null,
            editLayoutDialog: false,
            editLayoutDialogLoading: false,

            element: null,
            editElementDialog: false,
            editElementDialogLoading: false,

            users: [],
            user: null,

            editUserDialog: false,
            editUserDialogLoading: false,

    

            productVariant: null,
            productVariantDialog: false,
            productVariantDialogLoading: false,

            orders: [],
            fullOrders: [],
            orderSearch: '',
            resendingEmail: false,

            invoice: null,
            invoiceDialog: false,
            invoiceDialogLoading: false,

            users_count: 0,

            window,
            builderData: null,

            isMobile: window.innerWidth < 768,

            placeholder

        }
    },


    methods: {
        async deleteLayout() {
            this.editLayoutDialogLoading = true;
            var result = await apiCall('DELETE', '/Product/Layout/' + this.layout.id);
            this.editLayoutDialogLoading = false;
            if (result.status == 200) {
                // remove from product
                this.product.layouts = this.product.layouts.filter(l => l.id != this.layout.id);
                this.layout = null;

                this.editLayoutDialog = false;
                this.getProducts();
            }
        },

        async showProduct(n){
            // download picture
            if (n != null) {
                if (n.fileId != null) {
                    const signedUrl = await s3Client.getSignedDownloadURL({name: n.fileId});

                    const exists = await s3Client.fileExists(signedUrl);
                    if(exists){
                        n.previewFileUrl = signedUrl.url;
                    }
                }

                // download pictures of layout previews
                await Promise.all(n.layouts.map(async (l) => {
                    if (l.previewPictureId != null) {
                        const signedUrl = await s3Client.getSignedDownloadURL({name: l.previewPictureId});

                        const exists = await s3Client.fileExists(signedUrl);
                        if(exists){
                            l.previewFileUrl = signedUrl.url;
                        }
                    }
                }));
            }

            


            this.product = n;
        },

        uploadFile(id, scope, types) {
            // set attributes
            this.$refs.fileInput.setAttribute('data-file-name', id);
            this.$refs.fileInput.setAttribute('data-file-scope', scope);
            this.$refs.fileInput.setAttribute('accept', types);
            this.$refs.fileInput.click();
        },

        async fileInputChange(e) {
            var file = e.target.files[0];
            // get from data attrs
            const fileName = e.target.getAttribute('data-file-name');
            const fileScope = e.target.getAttribute('data-file-scope');
            if (file) {
                if(file.size > 1024 * 1024 * 10){
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `La foto non può essere più grande di 10MB.`
                    })
                    return;
                }
                const loading = this.$vs.loading({
                    text: 'Caricamento in corso...'
                })
                try {
                    const result = await s3Client.uploadFile(file, fileName);
                    if(result.status == 200){
                        // get signed download url
                        const signedUrl = await s3Client.getSignedDownloadURL({name: fileName});
                        if(fileScope == 'productPreview'){
                            this.product.previewFileUrl = signedUrl.url;
                            this.product.__ob__.dep.notify();
                        }
                    } else {
                        throw new Error('Error uploading file');
                    }
                } catch (error) {
                    console.error(error);
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Impossibile caricare la foto.`
                    })
                }

                loading.close();
            }
        },

        openInBuilder(layout, productVariant) {
            this.active = 'builder';
            this.builderData = {
                layout: layout || this.layout,
                product: this.product,
                productVariant: productVariant || this.productVariant
            }

            // close dialog
            this.editLayoutDialog = false;
            this.editProductDialog = false;
        },

        async getUsersCount(){
            var result = await apiCall('GET', '/User/Count');
            if (result.status == 200) {
                this.users_count = result.data.count;
            }
        },
        

        async saveInvoice() {
            this.invoiceDialogLoading = true;
            var oldStatus = this.invoice.status;
            this.invoice.status = 'PAID';
            var result = await apiCall('PATCH', '/Order/Administrator/Invoice', this.invoice);
            this.invoiceDialogLoading = false;
            if (result.status == 200) {
                this.invoiceDialog = false;
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Pagamento convalidato con successo.`
                })
                this.getOrders();
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile completare l'operazione.`
                })
                this.invoice.status = oldStatus;
            }
        },
        async resendEmail(order) {
            this.resendingEmail = true;
            var result = await apiCall('POST', '/Order/Administrator/Resend/' + order.id);
            this.resendingEmail = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Email schedulata con successo.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile completare l'operazione.`
                })
            }
        },

        downloadOrder(o) {
            window.open(baseUrl + '/Order/Administrator/' + o.id + '/Download', "_blank");
        },

        viewInvoice(order) {
            // open stripe invoice in new tab, https://dashboard.stripe.com/test/invoices/in_1OPSwBIg6B130mWneVFdf84Y
            window.open(`https://dashboard.stripe.com/invoices/${order.invoices[0].stripeInvoiceId}`, '_blank');
        },

        viewCustomer(order) {
            // open stripe customer in new tab https://dashboard.stripe.com/test/customers/cus_PDuccrSI06sTmw
            window.open(`https://dashboard.stripe.com/customers/${order.user.stripeCustomerId}`, '_blank');
        },

        

        addProductVariant() {
            this.productVariant = {
                productId: this.product.id,
                pages: 0,
                price: 0,
                digitalPrice: 0,
                stripePriceId: '',
                digitalStripePriceId: ''
            }
        },


        

        async getProducts() {
            var result = await apiCall('POST', '/Product'); // post instead of get because of conflict in user endpoint
            if (result.status == 200) {
                this.products = result.data;
            }
        },

        async getOrders() {
            var result = await apiCall('GET', '/Order/Administrator'); // post instead of get because of conflict in user endpoint
            if (result.status == 200) {
                this.orders = result.data;
                this.fullOrders = result.data;
            }
        },



        async getUsers() {
            var result = await apiCall('GET', '/User/All');
            if (result.status == 200) {
                this.users = result.data;
            }
        },



        async saveProduct() {
            this.editProductDialogLoading = true;
            var result = await apiCall('PATCH', '/Product', {
                id: this.product.id,
                title: this.product.title,
                description: this.product.description,
                width: parseFloat(this.product.width.toString().replace(',', '.')),
                height: parseFloat(this.product.height.toString().replace(',', '.')),
                fileId: this.product.fileId,
                marginTop: parseFloat(this.product.marginTop.toString().replace(',', '.')),
                marginLeft: parseFloat(this.product.marginLeft.toString().replace(',', '.')),
                marginRight: parseFloat(this.product.marginRight.toString().replace(',', '.')),
                marginBottom: parseFloat(this.product.marginBottom.toString().replace(',', '.'))
            });
            this.editProductDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async saveProductVariant() {
            this.productVariantDialogLoading = true;
            var result = await apiCall('PUT', '/Product/ProductVariant', {
                id: this.productVariant.id,
                productId: this.product.id,
                pages: parseInt(this.productVariant.pages),
                dorsino: parseInt(this.productVariant.dorsino),
                stripePriceId: this.productVariant.stripePriceId,
                digitalStripePriceId: this.productVariant.digitalStripePriceId,
                price: parseFloat(this.productVariant.price.toString().replace(',', '.')),
                digitalPrice: parseFloat(this.productVariant.digitalPrice.toString().replace(',', '.')),
                spineLayoutId: this.productVariant.spineLayoutId,
            });
            this.productVariantDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })

                // reload product
                this.getProducts();
                this.productVariant = null;
                this.productVariantDialog = false;
                this.product.productVariants.push(result.data);
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async saveUser() {
            this.editUserDialogLoading = true;
            var result = await apiCall('PATCH', '/User/All', {
                id: this.user.id,
                email: this.user.email,
                name: this.user.name,
                surname: this.user.surname,
                address: this.user.address,
                addressNo: this.user.addressNo,
                city: this.user.city,
                postalCode: this.user.postalCode,
                isAdmin: this.user.isAdmin,
                password: this.user.password,
                proPicFileId: this.user.proPicFileId,
                country: this.user.country,
                mobile: this.user.mobile,
                vat: this.user.vat,
                cf: this.user.cf,
                stripeCustomerId: this.user.stripeCustomerId,

            });
            this.editUserDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },




        async addLayout() {
            this.layout = {
                id: 0,
                productId: this.product.id,
                name: '',
                jsonData: '',
                previewPictureId: '',
                type: 'Page'
            }
            this.editLayoutDialog = true;
        },

        async editLayout() {
            this.editLayoutDialogLoading = true;
            var result = await apiCall('PATCH', '/Product/Layout', {
                id: this.layout.id,
                productId: this.layout.productId,
                name: this.layout.name,
                jsonData: this.layout.jsonData,
                previewPictureId: this.layout.previewPictureId,
                type: this.layout.type
            });
            this.editLayoutDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })

                // if it was new (id == 0) add to product
                if (this.layout.id == 0) {
                    this.product.layouts.push(result.data);
                    this.layout = null; // close dialog
                    this.editLayoutDialog = false;

                }
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },



        

        openProductFile(id, pdf = false) {
            window.open(baseUrl + '/Layout/File/' + id + (pdf ? '/PDF' : ''), '_blank');
        }
    },

    watch: {
        orderSearch(n) {
            if (n != '') {
                this.orders = this.fullOrders.filter(o => (
                    o.id.toString().includes(n) ||
                    o.product.title.toLowerCase().includes(n.toLowerCase()) ||
                    o.invoices[0].status.toLowerCase().includes(n.toLowerCase())
                ));
            } else {
                this.orders = this.fullOrders;
            }
        },

        invoice(n) {
            if (n != null) {
                this.invoiceDialog = true;
            }
        },

        invoiceDialog(n) {
            if (!n) {
                this.invoice = null;
            }
        },

        active(n) {
            console.log(n);
            if (n == 'products') {
                this.getProducts();
            } else if (n == 'users') {
                this.getUsers();
            } else if (n == 'orders') {
                this.getOrders();
            }
        },

        '$parent.user'(n) {
            if (n != null) {
                this.active = 'products';
            }
        },



        user(n) {
            if (n != null) {
                this.editUserDialog = true;
            }
        },

        editUserDialog(n) {
            if (!n) {
                this.user = null;
            }
        },

        async product(n) {
            if (n != null) {
                this.editProductDialog = true;
            }
        },

        layout(n) {
            if (n != null) {
                this.editLayoutDialog = true;
            }
        },

        element(n) {
            if (n != null) {
                this.editElementDialog = true;
            }
        },

        editProductDialog(n) {
            if (!n) {
                this.product = null;
            }
        },

        editLayoutDialog(n) {
            if (!n) {
                this.layout = null;
            }
        },

        editElementDialog(n) {
            if (!n) {
                this.element = null;
            }
        },

        productVariant(n) {
            if (n != null) {
                this.productVariantDialog = true;
            }
        },

        productVariantDialog(n) {
            if (!n) {
                this.productVariant = null;
            }
        }
    },

    async mounted() {
        if (this.$parent.user != null) {
            this.active = 'products';
        }
        this.getUsersCount();

        // on resize
        window.addEventListener('resize', () => {
            this.isMobile = this.window.innerWidth < 768;
        });
    }
})
</script>

<style>
.dialog-large .vs-dialog {
    width: 50%;
}

.vs-select__label--label {
    margin-left: 20px;
}

.vs-table-content {
    overflow-y: scroll;
    height: 100%;
}

.dialog-disabled .vs-dialog__content, .dialog-disabled .vs-dialog__footer, .dialog-disabled label{
    pointer-events: none;
    cursor: not-allowed!important;
}

.admin-mobile {
    padding-left: 70px!important;
    padding-top: 10px!important;
}
</style>
