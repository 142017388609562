<template>
    <vs-row direction="column">
    
    <input type="file" multiple @change="fileInputChange" style="display: none;" ref="fileInput" accept="image/*" />

    <vs-row justify="flex-end">
        <vs-button @click="$refs.fileInput.click()">Carica</vs-button>
    </vs-row>
    <vs-row>
        <vs-table striped style="width: 100%;" v-model="font">
            <template #thead>
                <vs-tr>
                    <vs-th style="width: 5%;">
                        Id
                    </vs-th>
                    <vs-th>
                        Preview
                    </vs-th>
                    <vs-th style="width: 10%;text-align: right;">
                        Azioni
                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in builderImages" >
                    <vs-td>
                        {{ tr.id }}
                    </vs-td>
                    
                    <vs-td>
                        <img :src="tr.imageUrl" style="max-width: 100px; max-height: 100px;" />
                    </vs-td>
                    
                    <vs-td style="display: flex; justify-content: flex-end;">
                        <vs-button @click="deleteBuilderImage(tr.id)">Elimina</vs-button>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vs-row>
</vs-row>
</template>

<script>
import {
    apiCall,
} from '../../client';

import { s3Client } from '../../s3Client';
export default {
    data() {
        return {
            builderImages: [],
            builderImage: null,


            builderImageDialog: false
        }
    },
    methods: {
        async getBuilderImages() {
            var result = await apiCall('GET', '/BuilderImages');
            if (result.status == 200) {
                let images = await Promise.all(result.data.map(async (image) => {
                    const signedUrl = await s3Client.getSignedDownloadURL({name: image.id});
                    image.imageUrl = signedUrl.url;
                    return image;
                }));
                this.builderImages = images;
            }
        },

        async deleteBuilderImage(id) {
            var result = await apiCall('DELETE', '/BuilderImages/' + id);
            if (result.status == 200) {
                this.getBuilderImages();
            }
        },

        async fileInputChange() {
            var vm = this;
            const files = this.$refs.fileInput.files;

            for(var i = 0; i < files.length; i++){
                const file = files[i];
                if (file) {
                    if(file.size > 1024 * 1024 * 10){
                        this.$vs.notification({
                            icon: '<i class="bx bx-error"></i>',
                            color: 'danger',
                            position: 'top-right',
                            title: 'Ops!',
                            text: `La foto non può essere più grande di 10MB.`
                        })
                        return;
                    }
                    const loading = this.$vs.loading({
                        text: 'Caricamento ' + (i + 1) + ' di ' + files.length,
                    })
                    try {
                        
                        let newBuilderImage = null;
                        const newBuilderImageResult = await apiCall('PUT', '/BuilderImages', {});

                        if(newBuilderImageResult.status == 200){
                            newBuilderImage = newBuilderImageResult.data;
                        } else {
                            throw new Error('Error creating new builder image');
                        }

                       

                        const result = await s3Client.uploadFile(file, newBuilderImage.id);
                        if(result.status == 200){
                            // get signed download url
                            const signedUrl = await s3Client.getSignedDownloadURL({name: newBuilderImage.id});
                            newBuilderImage.imageUrl = signedUrl.url;
                            this.builderImages.push(newBuilderImage);
                        } else {
                            throw new Error('Error uploading file');
                        }
                    } catch (error) {
                        console.error(error);
                        this.$vs.notification({
                            icon: '<i class="bx bx-error"></i>',
                            color: 'danger',
                            position: 'top-right',
                            title: 'Ops!',
                            text: `Impossibile caricare la foto.`
                        })
                    }

                    loading.close();
                }
            }

            // reset
            this.$refs.fileInput.value = '';
            
        },

        
    },

    watch: {
        
    },

    mounted() {
        this.getBuilderImages();
    }
}
</script>